<template>
    <Result />
</template>

<script>
import Result from '@/components/Pay/Result'

export default {
    components: { Result }
}
</script>

<style lang="scss" scoped></style>